<template>
  <tm-popup
    :submit="onSubmit"
    title="اضافة صورة"
    ref="popup">
    <div class="vx-col  w-full text-center">
      <h4 class="my-5">صورة المنتج الفرعي</h4>
    </div>
    <file-pond-component
      :file="subProductImage.image_url"
      @fileUploaded="(files) => fileUploaded(files)"
      @fileDeleted="fileDeleted"
      project="website"
      ref="imageUploader"
    />
  </tm-popup>
</template>

<script>
  import {RepositoryFactory} from "../../repositories/ecommerceRepositoryFactory";
  import TmPopup from "../../../shared/shared-components/TmPopup";
  import FilePondComponent from "@/app/shared/shared-components/FilePondComponent";

  const subProductImageRepository = RepositoryFactory.get('subProductImage')
  export default {
    name: "SubProductImageStorePopup",
    props: {
      subProductId: {
        type: String,
        required: true
      },
    },
    data() {
      return {
        subProductImage: {
          image_url: null,
          is_primary: false,
        }
      }
    },
    methods: {
      onSubmit() {
        this.$refs.popup.loading();
        this.subProductImage.sub_product_id = this.subProductId;
        subProductImageRepository.store(this.$objectToFD(this.subProductImage)).then((response) => {
          this.$refs.popup.close();
          this.subProductImage.image_url= null;
          this.$emit("created", response.data);
        });
      },
      open() {
        this.$refs.popup.open();
      },
      fileUploaded(file) {
        this.subProductImage.image_url = file;
      },
      fileDeleted(){
        this.subProductImage.image_url = null;
      }
    },
    components: {
      TmPopup,
      FilePondComponent
    },
    created() {
    }
  }
</script>

